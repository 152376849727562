/* Global */

/* Tags */
img {
  height: inherit;
}

/* Classes */

/* IDs */

#information-popover {
  display: inline;
  height: inherit;
}
