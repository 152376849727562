.people-container {
  color: black;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
}

.header {
  padding-bottom: 25px;
}
