.export-button {
  color: var(--text-color-white);
}

.export-button:focus,
.export-button:hover {
  background-color: var(--color-dark-blue-muted);
  color: var(--text-color-white);
}

.csvLink,
.csvLink:focus,
.csvLink:hover {
  color: var(--text-color-white);
  text-decoration: none;
}

.site-export {
  max-height: 36px;
  width: 160px;
  color: var(--text-color-white);
}

.site-export:focus,
.site-export:hover {
  background-color: var(--color-dark-blue-muted);
  color: var(--text-color-white);
}
