/* Global */

/* Tags */
.area-dropdown a {
  color: unset !important;
}
.area-dropdown p {
  margin: 0 !important;
}
/* Classes */
.area-dropdown {
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 2px 5px 0px rgb(52 52 52 / 24%);
}
/* Classes - area-dropdown__closed */
.area-dropdown__closed_container {
  display: flex;
  height: fit-content;
  align-items: center;
  padding: 4px 8px;
}
.area-dropdown__closed-area-name-wrapper {
  display: flex;
  margin-left: 0.5em;
  width: 100%;
}
.area-dropdown__closed-area-name {
  margin: 0;
}
.area-dropdown__closed-area-edit-icon {
  margin-left: 5px;
}
.area-dropdown__closed-area-caret {
  margin-left: auto;
}

/* Classes - area-dropdown__open */
.area-dropdown__open-container {
  padding: 5px;
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.25fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. . .';
}
.area-dropdown__open__area_stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 22px);
}
.area-dropdown__open__area_stats__section {
  display: flex;
  align-items: center;
  font-size: 19px;
  margin-bottom: 5px;
  margin-left: 20px;
}
.area-dropdown__open__area_stats__section img {
  height: 19px;
  width: 19px;
}
.area-dropdown__open__area_stats__section-icon {
  margin-right: 8px;
}
.area-dropdown__open__site-lookup-container {
  border: 1px solid #b2b2b2;
  height: calc(100% - 22px);
  overflow-y: scroll;
}
.area-dropdown__open__site-lookup-wrapper {
  margin: 10px 18px 0px;
}
.area-dropdown__open__site-lookup__header {
  border-bottom: 1px solid #b2b2b2;
  font-weight: bold;
  position: sticky;
  top: 0;
  background: white;
}
.area-dropdown__open__site-lookup__body {
  display: flex;
  flex-direction: column;
}
.area-dropdown__open__site-link {
  padding: 1px 0;
  transition: all 100ms ease-in-out;
  text-decoration: none;
}
.area-dropdown__open__site-link:hover {
  background: #0d6efd;
  color: white !important;
}

.area-dropdown__open__edit-sites-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
  text-decoration: underline;
  height: calc(100% - 22px);
}
/* IDs */

/* Media Query */
