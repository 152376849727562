// This file sets the default bootstrap variants
// variable overrides
$primary:       #3288C4 !default;
$secondary:     #5F758D !default;
$success:       #28A745 !default;
$info:          #17A2B8 !default;
$warning:       #FFD350 !default;
$danger:        #E53E3E !default;
$light:         #F8F9FA !default;
$dark:          #343A40 !default;

.btn-primary, .btn-success, .btn-danger, .btn-info {
  color: white !important;
}

@import '~bootstrap/scss/bootstrap.scss'; // must be at the bottom of the file

