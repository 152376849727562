.student-view {
  margin: 5% 11%;
}
.student-view h2 {
  font-size: 1.35em;
}
.student-return-name-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.student-return-name-section h2 a {
  font-size: calc(1em - 2px);
  text-decoration: none;
}

.student-information-section__title-button-wrapper {
  display: flex;
  justify-content: space-between;
}
.student-information-section__title-button-wrapper h2 {
  margin: 0;
}
.student-information-section__title-and-delete {
  display: flex;
  justify-content: left;
}
.delete-button {
  margin-left: 10px;
}
.student-information-section__student-info-table-container table {
  margin: 10px 0;
}
.student-information-section__student-info-table-container thead {
  background-color: #ffe79f;
}
.student-information-section__student-info-table-container thead th {
  border: 1px #b9b9b9 solid;
  text-align: center;
}
.student-information-section__student-info-table-container tbody tr td {
  border: 1px #b9b9b9 solid;
  text-align: center;
}
.student-information-section__student-info-table-container tbody tr td input {
  margin: 0;
}

.data-information-section {
}
.data-information-section__data-graphs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 400px;
  margin-bottom: 30px;
}
.data-information-section__data-graph {
  text-align: center;
  width: 45%;
  height: 100%;
}
.data-information-section__data-graph p {
  font-size: 15px;
  margin-bottom: 0;
  display: inline-block;
}
.links-section {
}
.links-section__link-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
}
.links-card-container {
  text-align: center;
  margin: 10px 15px 15px;
  text-decoration: none;
  color: black;
  transition: transform 200ms ease-in-out;
}
.links-card-container:hover {
  transform: scale(1.05);
  transition: transform 200ms ease-in-out;
}
.links-card-container__1 {
  grid-area: 1 / 1 / 2 / 2;
}
.links-card-container__2 {
  grid-area: 1 / 2 / 2 / 3;
}
.links-card-container__3 {
  grid-area: 2 / 1 / 3 / 3;
}
.links-card__assessment-score-card {
  background-image: url('../../assets/images/assessment-score-card.png');
}
.links-card__reading-attitude-survey {
  background-image: url('../../assets/images/reading-attitude-survey.png');
}
.links-card__student-group {
  background-image: url('../../assets/images/student-group.png');
}

.links-card__overlay {
  height: 460px;
  width: 300px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  filter: blur(1px) drop-shadow(0px 4px 4px #6a91bc);
  margin-bottom: 10px;
}

@media (max-width: 1025px) {
  .data-information-section__data-graphs-container {
    flex-direction: column;
    align-items: center;
  }
  .data-information-section__data-graph {
    width: 100%;
    height: 50%;
    margin-bottom: 25px;
  }
  .links-section__link-cards-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .links-card-container__1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .links-card-container__2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .links-card-container__3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .links-card__overlay {
    height: 320px;
    width: 220px;
  }
}

@media (max-width: 600px) {
  .student-return-name-section {
    align-items: flex-start;
    flex-direction: column;
  }
  .student-information-section__title-button-wrapper {
    flex-direction: column;
  }
  .student-information-section__title-button-wrapper button {
    margin-top: 10px;
  }
  .student-return-name-section h2 a {
    font-size: calc(1em - 1vw);
  }
}
