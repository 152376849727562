.logo {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 40%;
  height: 40%;
  padding-bottom: 50px;
}

.outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}

.form-wrapper {
  border-radius: 20px;
  background: #003459;
}

.recover {
  color: white;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 48px 0px 16px 0px;
  margin: 0;
}

.recover-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
}

.email-input {
  width: 60%;
}

.rec-label {
  color: white;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  text-align: center;
}

.error-message {
  color: red;
  position: relative;
  margin-top: -60px;
  padding-bottom: 10px;
  font-size: 20px;
}

#email-input {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4.8px;
  height: 46px;
  width: 100%;
  font-family: 'Calibri';
  margin-top: 10px;
}

/* SUBMITS EMAIL FOR RECOVERY */
#recover-button {
  padding: 11px 50px 11px 50px;
  margin-bottom: 7px;
  border-radius: 4.8px;
  border: none;

  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212529;
  background: #e5e5e5;

  /* background: #3288c4; */
  /* background: #28a745; */
  border-radius: 4.8px;

  margin: 33px 0px 80px 0px;
}
