.log-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
}

.password-input {
  display: inline-flex;
  width: 100%;
}

.eye-icon {
  position: relative;
  cursor: pointer;
  color: #212529 !important;
  left: -1.5em !important;
  top: 0.7em;
}

.password-popover {
  height: 20px;
  width: 20px;
}

.input-custom {
  padding-right: 30px;
}
