@import '../../common/vars.css';

.edit-student-group-modal {
  background-color: #fdfdfd;
  color: #000;
  font-family: var(font-family), Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  width: 90%;
  height: 1000px;
  border: 1.5px solid #ced4da;
  border-radius: 10px;
  position: absolute;
  top: 10%;
  left: 25%;
}

.edit-student-group-modal-header {
  padding: 15px 10px 15px 10px;
  display: flex;
  justify-content: center;
  border-bottom: 1.5px solid #ced4da;
}

.edit-student-group-modal-top-bar-title {
  font-size: 25px;
  font: Calibri;
  text-align: center;
}

.edit-student-group-modal-body {
  padding: 0 3em 7em 3em;
}

.edit-student-group-modal-footer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.edit-student-group-cancel-button {
  margin: 0 1em 0 0;
}

.edit-student-group-delete-button {
  margin: 0 1em 0 0;
}

.edit-student-group-save-button {
  margin: 0 1em 0 0;
}

.edit-student-group-school-cycle {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

.edit-student-group-meeting {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

.edit-student-group-modal-field-desc {
  padding: 30px 10px 10px 5px;
  font-size: 20px;
}

.edit-student-group-badges {
  display: flex;
  flex-wrap: wrap;
}

.modal-text-input {
  color: #767676;
  border-radius: 10px;
  text-align: left;
  padding: 0.5em 0 0.5em 1em;
  width: 98%;
  border-radius: 10px;
  border: 1px solid #ced4da;
  line-height: 32px;
  font-family: var(font-family), Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.modal-text-input:focus {
  color: #767676;
  border-radius: 10px;
  text-align: left;
  padding: 0.5em 0 0.5em 1em;
  width: 98%;
  border: 1px solid #80bdff;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
  outline: none;
  line-height: 32px;
  font-family: var(font-family), Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.student-badge {
  margin-right: 1em;
  margin-bottom: 1em;
  background-color: var(--color-gray-white) !important;
  color: #212529;
  padding: 10px;
  padding-right: 15px;
  font-size: 0.85em;
}

.student-badge-icon {
  margin-right: 6px;
  padding: 2px;
}

.time-input {
  width: 50%;
  height: 49px;
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 0px 15px 0px 15px;
  color: #6c757d;
}

.time-input:focus {
  width: 50%;
  height: 49px;
  border-radius: 10px;
  border: 1px solid #80bdff;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
  outline: none;
  padding: 0px 15px 0px 15px;
  color: #6c757d;
}

.alert-custom {
  position: fixed;
  top: 1em;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 20em;
  display: inline-block;
  vertical-align: middle;
}
