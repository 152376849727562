.create-admin-modal {
  background-color: #fdfdfd;
  color: #000;
  font-family: 'Calibri', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  width: 30%;
  height: 250px;
  border: 1.5px solid #ced4da;
  border-radius: 10px;
  position: absolute;
  top: 25%;
  left: 35%;
}

.create-admin-modal-top-bar {
  padding: 15px 10px 15px 10px;
  display: flex;
  justify-content: center;
  border-bottom: 1.5px solid #ced4da;
}

.create-admin-modal-top-bar-title {
  text-align: center;
}

.create-admin-modal-body {
  padding: 10px 15%;
}

.create-admin-modal-field-desc {
  padding: 20px 10px 15px 10px;
}

.modal-text-input {
  border: 1px solid #ced4da;
  color: #767676;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ced4da;
  line-height: 32px;
  font-family: 'SF Compact', Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.create-admin-modal-exit-button {
  position: absolute;
  top: 8px;
  right: 5px;
  background-color: #fdfdfd;
  /* border: 1px dotted black; */
  border: 0px;
  font-weight: 800;
  cursor: pointer;
}

.create-admin-modal-send-another-button {
  background-color: #17a2b8;
  color: #fdfdfd;
  width: 188px;
  height: 36px;
  border-radius: 4px;
  size: 16px;
  text-align: center;
  border: 0px;
  padding: 6px, 12px, 6px, 12px;
  position: absolute;
  bottom: 20px;
  right: 130px;
  cursor: pointer;
}

.modalSave {
  background-color: #3288c4;
  color: #fdfdfd;
}

.phoneNumField,
.emailField {
  width: 100%;
}

.fNameField {
  width: 40%;
  margin-right: 5%;
}

.lNameField {
  width: 55%;
}

.modalTitle {
  margin-left: 27.5%;
}

.err-msg {
  margin-left: 1em;
}

.requiredSubtitles::after {
  color: #e32;
  content: ' *';
  display: inline;
}

/* all the text areas */
.requiredSubtitles {
  font-family: Calibri, 'sans-serif';
  font-size: 16px;
  padding-left: 2px;
}
