.button,
.title,
.log-label {
  color: white;
  font-family: 'Calibri';
  font-style: normal;
}

.logo-wrapper {
  margin-top: -100px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 40%;
  height: 40%;
  padding-bottom: 50px;
}

/* ADMIN/TEACHER USER TABS */
.user-tabs {
  display: flex;
  justify-content: center;
}
#admin-button {
  background: #1a4767;
  border-radius: 4px 0px 0px 4px;
}
#teacher-button {
  background: #6a91bc;
  border-radius: 0px 4px 4px 0px;
}

/* BODY */
.form-wrapper {
  background-color: #003459;
  border-radius: 20px;
}

.title {
  font-weight: 300;
  font-size: 56px;
  line-height: 67px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fffafa;
  padding: 45px 0px 15px 0px;
}

/* CONTAINS INPUTS, LABELS, AND SUBMIT BUTTON */
.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
}

/* LABELS AND INPUTS */
.email-input,
.password-input,
.remember-me-box {
  width: 60%;
}

/* label, */
.log-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}
#email,
#password {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4.8px;
  height: 32px;
  width: 100%;
  font-family: 'Calibri';
}
.loginForm #eyeIcon {
  position: absolute;
  cursor: pointer;
  color: #212529 !important;

  margin: 8px 0px 0px -25px;
}

/* CHECKBOX */
.remember-me-box {
  padding-bottom: 26px;
}

.remember-me-text {
  position: relative;
}

#remember-box {
  margin: 0px 10px 0px 0px;
}

/* LOGIN BUTTON */
#submit-button {
  padding: 5px 16px;
  margin-bottom: 7px;
  width: 40%;
  border-radius: 4.8px;
  border: none;

  font-family: 'Calibri';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212529;

  background: #e5e5e5;
  border-radius: 4.8px;
}

/* FORGOT PASSWORD AND NO ACCOUNT LINKS */
#forgot-password {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 39px;
}

/* MOBILE VERSION RESIZE */
@media only screen and (max-width: 575px) {
  .form-wrapper {
    width: 80%;
  }
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
