.error {
  padding-top: 30px;
  width: 750px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.warning {
  font-size: 70px;
}

.text {
  font-size: 25px;
  margin-top: -15px;
}

@media only screen and (max-width: 600px) {
  .error {
    width: 375px;
  }

  .content {
    width: 370px;
  }

  .warning {
    font-size: 35px;
  }

  .text {
    font-size: 20px;
  }
}
