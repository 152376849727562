.checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 1rem;
}

.form-check {
  min-width: 100px;
}

.label-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}
