.modalTitle {
  align-content: 'center';
  text-align: 'center';
  align-items: 'center';
  align-self: 'center';
}

.mb-5 {
  align-items: center;
}
