.setting-view {
  font-family: Calibri, sans-serif;
  color: #003459;
}

#settings-title {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

#mt-settings #admin-settings {
  margin-left: 5em;
  margin-right: 5em;
}

.container-sect {
  margin-bottom: 2em;
}

.section-val {
  margin-left: 1em;
  margin-bottom: 1em;
}

.mt-status {
  font-weight: 700;
}

#logout {
  color: #e53e3e;
  font-weight: 700;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
}

#change-pwd-btn {
  margin-left: 1em;
}
