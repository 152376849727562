.alert-custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 450px;
  display: inline-block;
  vertical-align: middle;
}

.alert-close-btn {
  margin-left: 5em !important;
  font-size: 0.5em !important;
}

.alert-link-custom {
  color: #084298 !important;
}

#asterisk {
  color: red;
}
