.master-teacher-container {
  padding: 50px;
}

.table-header {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  gap: 5px 10px;
}

.table-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px 10px;
  align-items: center;
  margin: 0.5em auto 2em;
}

.export-button {
  background-color: var(--color-dark-blue-muted);
  color: var(--text-color-white);
}

button {
  white-space: nowrap;
}

.reset-button {
  background-color: var(--color-gray-blue-muted);
}

.notes-button {
  text-align: center;
}
