.site-form {
  display: block;
}

.form-wrapper {
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  align-items: left;
}

.form-header {
  height: 90px;
  background-color: rgba(178, 178, 178, 0.5);
}

.form-title {
  font-size: 28px;
  padding-left: 20px;
  padding-top: 25px;
}

.routing {
  margin-left: 200px;
  padding: 30px 0px 20px 0px;
  font-size: 24px;
  color: var(--text-color-dark-gray);
}

.link {
  font-size: 24px;
  color: var(--color-baby-blue) !important;
  text-decoration: none;
}

.text {
  margin: 10px;
}

.optional-subtitles {
  font-family: Calibri, 'sans-serif';
  font-size: 24px;
  padding-left: 30px;
}

.input-area {
  font-family: Calibri, 'sans-serif';
  font-size: 16px;
  padding: 0px 30px 10px 50px;
}

.form-control {
  margin-top: 5px;
}

/* save button */
.save-btn {
  width: 160px;
  background-color: var(--color-baby-blue);
  color: var(--text-color-white);
  margin: 60px 0px 30px 70px;
}

.edit-btn {
  width: 160px;
  background-color: var(--color-yellow);
  color: var(--text-color-dark);
  margin: 60px 0px 30px 70px;
}

.edit-btn:hover,
.delete-btn:hover,
.save-btn:hover,
.cancel-btn:hover {
  background-color: var(--color-dark-blue-muted);
  color: var(--text-color-white);
}

.delete-btn {
  width: 160px;
  background-color: var(--color-red);
  color: var(--text-color-white);
  margin: 60px 0px 30px 70px;
}

.cancel-btn {
  position: relative;
  left: 70px;
  width: 160px;
  background-color: #5f758d;
  color: var(--text-color-white);
  margin: 60px 0px 30px 70px;
}

.input-fields-coalesce-wrapper {
  display: flex;
  width: 520px;
  justify-content: space-between;
}

.addr-small-field {
  width: 90%;
  margin-right: 10%;
}

.page-inputs {
  width: 255px;
}

.notes {
  width: 700px;
}

.status {
  width: 255px;
  height: 36px;
  border-radius: 5px;
  border-color: var(--color-form-gray);
}

/* Site Status Header and CSV button */
.title-csv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 30px;
}

.csv-btn-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.err-msg {
  margin-left: 1em;
}
