/* Removing increment/decrement arrows for ZIP number field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.site-form {
  display: block;
}

.form-wrapper {
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  align-items: left;
}

.form-header {
  height: 90px;
  background-color: rgba(178, 178, 178, 0.5);
}

.form-title {
  font-size: 28px;
  padding-left: 20px;
  padding-top: 25px;
}

.required-subtitles::after {
  color: #e32;
  content: ' *';
  display: inline;
}

/* all the text areas */
.required-subtitles {
  font-family: Calibri, 'sans-serif';
  font-size: 24px;
  padding-top: 20px;
  padding-left: 30px;
}

.optional-subtitles {
  font-family: Calibri, 'sans-serif';
  font-size: 24px;
  padding-top: 20px;
  padding-left: 30px;
}

.input-area {
  font-family: Calibri, 'sans-serif';
  font-size: 16px;
  padding: 0px 30px 10px 50px;
}

.label {
  padding-top: 10px;
}

.form-control {
  margin-top: 5px;
}

.input-fields-coalesce-wrapper {
  display: flex;
  width: 380px;
  justify-content: space-between;
}

.addr-small-field {
  width: 90%;
}

.addr-small-field-invalid {
  border: 1px solid #e53e3e;
  width: 90%;
}

.err-msg {
  margin-left: 1em;
}
