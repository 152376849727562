@import '../../common/vars.css';
.alert {
  position: fixed;
  top: 8%;
  left: 0;
  right: 0;
  max-width: fit-content;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.close-btn {
  margin-left: 20px !important;
  font-size: 10px !important;
  font-weight: bold !important;
}
