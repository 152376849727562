.image {
  width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  /* width: 600px; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text {
  font-size: 80px;
  font-weight: 400px;
}

.spinner {
  width: 4rem;
  height: 4rem;
}
