.error {
  padding-top: 50px;
  width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.text {
  font-family: 'Calibri';
  font-size: 40px;
}

.child {
  width: 400px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

@media only screen and (max-width: 600px) {
  .error {
    width: 350px;
  }

  .content {
    width: 350px;
  }

  .text {
    font-size: 24px;
  }

  .child {
    width: 300px;
  }
}
