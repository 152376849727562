.outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}

.form-wrapper {
  width: 540px;
  height: 380px;
  border-radius: 20px;
  background: #003459;
}

.logo {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  width: 40%;
  height: 40%;
  padding-bottom: 50px;
}

.recover {
  color: white;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.02em;

  padding: 48px 0px 16px 0px;
  margin: 0;
}

.new-password,
.re-enter-password {
  width: 60%;
}

.log-label {
  color: white;
  font-family: Calibri;
  font-style: normal;
}

form #eye-icon {
  position: absolute;
  cursor: pointer;
  color: #212529 !important;

  margin: 8px 0px 0px -25px;
}

#submit-button {
  padding: 5px 16px;
  margin-top: 20px;
  margin-bottom: 7px;
  border-radius: 4.8px;
  border: none;

  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212529;

  /* background: #3288c4; */
  /* background: #28a745; */
  background: #e5e5e5;
  border-radius: 4.8px;
}

#password {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4.8px;
  height: 32px;
  width: 100%;
  font-family: 'Calibri';
}

#password-valid {
  background: #ffffff;
  border: 1px solid #e53e3e;
  box-sizing: border-box;
  border-radius: 4.8px;
  height: 32px;
  width: 100%;
  font-family: 'Calibri';
}

#password-invalid {
  background: #ffffff;
  border: 1px solid #e53e3e;
  box-sizing: border-box;
  border-radius: 4.8px;
  height: 32px;
  width: 100%;
  font-family: 'Calibri';
}

.error-message {
  width: 60%;
  font-size: 13px;
  padding-top: 10px;
  color: #e53e3e;
}
