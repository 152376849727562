.site_dropdown_inner {
  padding: 1px 2px 1px 5px;
}

.site_dropdown_button {
  text-decoration: none;
}

.notes_icon {
  cursor: pointer;
  height: 32px;
  width: auto;
  margin-top: 6px;
  margin-left: 2px;
}

.view_site_info_btn {
  color: var(--text-color-white);
  margin-top: 3px;
  margin-left: 3px;
}

.tableButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.create_site_btn {
  height: 38px;
  line-height: 28px;
  padding-top: 5px;
}

.create_button_plus {
  cursor: pointer;
  height: 28px;
  width: auto;
  margin-left: 2px;
  margin-bottom: 2px;
}

.sites_filter_input {
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
}

.sort {
  display: flex;
  margin-left: auto;
}

.filter_by_icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.filter_by_btn {
  color: var(--text-color-white);
  padding-right: 10px;
  margin-right: 15px;
}

.sort_by_btn {
  color: var(--text-color-white);
  padding-right: 15px;
}

.arrow {
  position: relative;
  left: 200px;
  margin-top: -30px;
  width: 80%;
  border-style: dashed dashed hidden hidden;
  border-color: #6c757d;
}

.emptyArea {
  color: #6c757d;
  font-weight: 300;
  font-size: 56px;
  line-height: 67px;
  margin-top: 150px;
}

.no_teacher_assigned {
  color: var(--color-teal);
}

.active_site_dd {
  color: var(--color-light-green);
}

.inactive_site_dd {
  color: var(--color-red);
}

.dropdown-button button {
  color: var(--text-color-white);
}

.search {
  display: flex;
  align-items: center;
  width: 300px;
  margin-left: 10px;
}
.search-bar {
  margin: 0 !important;
}
#search-icon {
  border-left: none !important;
  background-color: #fff !important;
}
