@import '../../common/vars.css';

.site_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.site_container > div {
  padding: 50px;
}

.main_content {
  width: 80%;
}

.areas_breadcrumb {
  font-size: 24px;
  text-decoration: none;
  color: #6c757d;
}

.all_areas_link {
  font-size: 28px;
  text-decoration: none;
}

.school-year-container {
  display: flex;
  align-items: center;
}

.school-year-dropdown {
  margin-left: 15px;
  margin-right: 15px;
}

.cycle-container {
  display: flex;
  align-items: center;
}

.cycle-dropdown {
  margin-left: 15px;
  margin-right: 15px;
}

.select_cycle_container {
  width: 24%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: auto;
  justify-content: flex-start !important;
}

.cycle_header {
  font-size: 24px;
  line-height: 1.5;
  text-decoration: none;
}

.search_query_filters {
  display: inline-flex;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
}

.search_query_filters > div {
  display: flex;
  justify-content: center;
  height: 10%;
}

.select_school_year,
.select_cycle {
  width: 150px;
  margin-left: clamp(2px, 5%, 15px);
}

.data {
  text-align: center;
  border-left: 1px solid #c4c4c4;
}

.graph {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 200px;
  width: 250px;
}

.stats {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  height: auto;
  width: 250px;
  padding: 20px;
  margin: 20px 0;
}

.btn-warning {
  background: #ffd350;
  text-decoration-color: black;
}

.export_stats_to_csv_btn {
  color: var(--text-color-white);
  text-align: center;
  align-items: center;
  margin: 0;
  /* position: absolute;
  right: 50px; */
}

.sidebar_data_text {
  text-align: center;
  padding-top: 30px;
  padding-right: 100px;
}

.graph_headings {
  text-align: center;
  padding-right: 100px;
}

.area-card-title {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.area-card-info {
  margin: 12px;
  text-align: left;
}

.area-card-info > p {
  margin: 0;
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: bold;
}

.area-card-info > p > img,
.area-mt-icon {
  margin-left: -10px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.empty_export_stats_to_csv_btn {
  background: #ffffff !important;
  border: 1px solid #5f758d !important;
  color: #5f758d !important;
}
