.dropdown-super-wrapper {
  height: 32px;
}

.dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ced4da;
  line-height: 32px;
  cursor: pointer;
  width: 100%;
  padding: 0 0.5em;
  min-height: 32px;
}

.dropdown-button {
  color: #767676;
  width: 95%;
  text-align: left;
  padding-left: 2%;
  padding-right: 8%;
  min-width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-arrow-up {
  width: 15px;
  margin-right: 10px;
  transform: rotate(180deg);
}

.dropdown-arrow-down {
  width: 15px;
  margin-right: 10px;
}

.dropdown-choices-wrapper {
  position: relative;
  z-index: 2;
  top: 33%;
  margin-top: 1px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background: #fdfdfd;
  padding: 0 0.5em;
  max-height: 140px;
  overflow-y: auto;
}

.dropdown-choice {
  padding-left: 2%;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  width: 110px;
}

.dropdown-choice:hover {
  text-decoration: underline;
}

.dropdown-disabled {
  background-color: #ced4da;
  cursor: not-allowed;
}
