@import '../../../common/vars.css';
.form-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 24px 0;
}

.form-header p {
  font-size: 28px;
  margin: 0;
  padding: 0;
  align-self: center;
}

.form-header button {
  margin: 0 4px;
}

.scorecard-table {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 30px;
}

.scorecard-table th,
td {
  border: 1px solid #b9b9b9;
}

.scorecard-table th {
  text-align: center;
  vertical-align: middle;
  padding: 5px 0;
  background: var(--color-dark-gray);
  color: white;
}

/* Row styles */

.assessment-row td {
  text-align: center;
  vertical-align: middle;
  height: 75px;
}

.assessment-row input {
  max-width: 48px;
}

.game-name {
  max-width: 350px;
  min-width: 350px;
  background: #ffe79f;
}

.skill-test {
  max-width: 240px;
  min-width: 240px;
}

.row-notes {
  text-align: left !important;
  vertical-align: top !important;
  word-break: break-all;
  max-width: 260px;
  min-width: 260px;
  padding: 3px;
}

.empty-notes {
  text-align: center !important;
  vertical-align: middle !important;
}

.row-note-input {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none !important;
  resize: none;
  vertical-align: middle;
  overflow-y: hidden;
}

.row-note-input::-webkit-input-placeholder {
  text-align: center;
  line-height: 70px;
}

.passing-score {
  max-width: 125px;
  min-width: 125px;
}

.player-score {
  max-width: 125px;
  min-width: 125px;
  background: var(--color-gray-white);
  font-weight: bold;
}

.input-error {
  border: 1px solid red;
}
.input-error:focus {
  outline: none !important;
  border: 1px solid red;
}
.basic-chip{
  padding: 5px 10px;
  border-radius: 50px;
  display: inline-flex;
  margin: 5px;
  background: var(--color-baby-blue);
  color: var(--color-gray-white);
}
.close-chip-icon {
  margin-left: 5px;
}
.chip-disabled {
  opacity: .35;
}