.alert-custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 500px !important;
  display: inline-block;
  vertical-align: middle;
}

.alert-close-btn {
  margin-left: 5em;
  font-size: 0.5em;
}

.alert-link-custom {
  color: #084298 !important;
}

.site-badge {
  margin-right: 1em;
  margin-bottom: 0.5em;
}

#status-inactive-text {
  color: #3288c4;
}

.buttons {
  color: var(--text-color-white);
}

.modalSave {
  background-color: #3288c4;
  color: #fdfdfd;
}

.phoneNumField,
.emailField,
.notesField {
  width: 100%;
}

.fNameField {
  width: 40%;
  margin-right: 5%;
}

.lNameField {
  width: 55%;
}

.modalTitle {
  margin-left: 40%;
}

.err-msg {
  margin-left: 1em;
}

.requiredSubtitles::after {
  color: #e32;
  content: ' *';
  display: inline;
}

/* all the text areas */
.requiredSubtitles,
.optionalSubtitles {
  font-family: Calibri, 'sans-serif';
  font-size: 16px;
  padding-left: 2px;
}

.undoChanges {
  cursor: pointer;
}
