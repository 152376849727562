.title {
  font-family: 'Calibri', sans-serif;
  font-size: 20px;
  padding-bottom: 0px;
}

.label {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
}
