.dropdown-toggle-button {
  padding: 15px;
  color: red;
}

.dropdown-toggle {
  text-align: left;
  width: 95%;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 10px;
  color: #6c757d;
  padding: 0.75em 1em 0.75em 1em;
}

.dropdown-toggle:focus {
  text-align: left;
  width: 95%;
  background-color: white;
  border: 1px solid #80bdff;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
  outline: none;
  border-radius: 10px;
  color: #6c757d;
  padding: 0.75em 1em 0.75em 1em;
}

.dropdown-toggle-error {
  text-align: left;
  width: 95%;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 10px;
  color: #6c757d;
  border: solid 1px #e53e3e;
  padding: 0.75em 1em 0.75em 1em;
}

.dropdown-toggle-error:focus {
  text-align: left;
  width: 95%;
  background-color: white;
  border: 1px solid #80bdff;
  box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
  outline: none;
  border-radius: 10px;
  color: #6c757d;
  padding: 0.75em 1em 0.75em 1em;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle-error::after {
  display: none !important;
}

.dropdown-toggle-icon {
  position: absolute;
  right: 15%;
  top: 35%;
  background-color: white;
}

.bs-dropdown-item:hover {
  font-weight: bold;
  background-color: white;
}

.meeting-day-dropdown {
  width: 50%;
}

.dropdown-option-checkmark {
  position: absolute;
  right: 10px;
  margin-top: 5px;
}

.bs-dropdown-selected-item {
  color: black;
  font-size: 19px;
}

.bs-dropdown-selected-item:active {
  background-color: #b2d4ff;
  color: black;
  font-size: 19px;
}

.bs-dropdown-unselected-item {
  color: #6c757d;
  font-size: 19px;
}

.bs-dropdown-unselected-item:active {
  background-color: #b2d4ff;
  color: black;
  font-size: 19px;
}

.dropdown-menu {
  width: 95%;
}
