.routing {
  padding: 50px 0px 30px 0px;
  font-size: 24px;
  color: #6c757d;
}

.link {
  font-size: 24px;
  color: #3288c4 !important;
  text-decoration: none;
}
