.space {
  padding: 2em;
}

.err-msg {
  padding-left: 1em;
}

.email-input {
  margin-top: 1em;
}
