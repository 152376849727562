.site-container {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 50px;
  height: calc(100vh - 101px); */
  padding: 50px 75px;
}
.site-container h1 {
  font-size: 1.8em;
}
.site-container h2 {
  font-size: 1.4em;
}

.tlp-button-primary {
  background-color: var(--color-baby-blue);
  color: var(--text-color-white);
}

.tlp-button {
  border: none;
  margin: 0;
}

.tlp-button-primary:hover {
  background-color: #2b79b1;
}

.area-content {
  width: 100%;
  display: flex;
  height: 100%;
}

.site-container-information {
  padding: 40px 50px 0 50px;
  height: 100%;
  width: 100%;
}

.school-year-container {
  display: flex;
  align-items: center;
}

.school-year-info {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.school-year-dropdown {
  margin-left: 15px;
  margin-right: 15px;
}

.search-school {
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-school-search-bar {
  margin: 0 !important;
  border-right: none !important;
}
#search-school-search-icon {
  border-left: none !important;
  background-color: #fff !important;
}

.area-button-options-container {
  margin: 50px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.create-new-area-button {
  margin: 0;
}
.area-button-options-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sites-data {
  text-align: center;
  border-left: 1px solid #c4c4c4;
  padding: 50px;
}

.sites-data > p {
  margin: 0;
  font-size: 14px;
}

.sites-graph {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 200px;
  width: 250px;
}

.area-data-stats {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  height: auto;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.area-data-title {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  font-weight: bold;
}

.area-data-info {
  margin: 12px;
  text-align: left;
}

.area-data-info > p {
  margin: 0;
  font-size: 16px;
  padding-bottom: 5px;
}

.area-data-info > p > img,
.area-mt-icon {
  margin-left: -10px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.btn-warning {
  background: #ffd350;
  text-decoration-color: black;
}
