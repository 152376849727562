.view-profile-btn {
  background-color: var(--color-baby-blue);
  color: var(--text-color-white);
}

td {
  padding: 10px 20px !important;
  width: auto;
  vertical-align: middle;
}

.view-group-col {
  text-align: center;
  width: 200px;
}

.table-heading {
  height: 70px;
}
