.student-container {
  padding: 20px;
}

.pie-chart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pie-chart-display {
  height: 500px;
  width: 500px;
  color: white !important;
}

@media (max-width: 1000px) {
  .pie-chart-display {
    height: 90% !important;
    width: 90% !important;
  }
}

.pie-chart-filter-link {
  color: #3288c4;
  cursor: pointer;
}

.export-button {
  background-color: var(--color-dark-blue-muted);
  color: var(--text-color-white);
}

.dropdown-button button {
  color: var(--text-color-white);
}

.filter-button {
  color: var(--text-color-white);
}

.create-btn {
  margin: 10px;
}

.create-btn:focus {
  outline: none;
}

.ctrl-group {
  width: 100%;
}

.inner-ctrl {
  display: inline-block;
  padding: 10px;
}

.ctrl-margin {
  margin-left: 1em;
}

.search {
  display: flex;
  align-items: center;
  width: 300px;
}
.search-bar {
  margin: 0 !important;
}
#search-icon {
  border-left: none !important;
  background-color: #fff !important;
}
